import React from "react";

function Home() {
    window.location.href = "https://www.electrifyze.com/";

    return (
        <>
        </>
    );
}

export default Home;
