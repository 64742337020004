import React from "react";

// Styles
import { Typography } from "@mui/material";

function StyledPageBreak(props) {
    const { title } = props
    
    return (
        <div style={{
            display: "flex",
            position: "relative",
            maxWidth: "100%",
            height: 90,
            paddingBottom: 0,
            width: "100%",
            backgroundColor: "black",
            alignItems: "center",
        }}>
            <div style={{
                width: 960,
                margin: '0 auto',
                paddingLeft: 15
            }}>
                <Typography variant="h2" sx={{
                    color: 'white',
                    fontSize: {
                        xs: 24,
                        md: 32, 
                    },
                    fontWeight: 600,
                    width: {
                        lg: '90%',
                        xl: 'auto'
                    }
                }}>
                    {title}
                </Typography>
            </div>
        </div>
    );
}
export default StyledPageBreak;