import React, { } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Styles
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function DescriptionBox(props) {
    const { data } = props;

    return (
        <Box sx={{
            width: '100%', 
            textAlign: 'center'
        }}>
            <Box sx={{
                maxWidth: 960,
                margin: '0 auto',
                marginTop: '40px', 
                marginBottom: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginLeft: {
                    xs: '10%',
                    md: 'auto'
                },
                marginRight: {
                    xs: '10%',
                    md: 'auto'
                }
            }}>
                <Box sx={{
                    width: 100, 
                    backgroundColor: 'black', 
                    height: {
                        xs: 4,
                        md: 5
                    },
                    marginBottom: '8px',
                }} />
                {data.banner.logo && (
                    <img src={data.banner.logo} style={{marginTop: 10, width: 350, height: 'auto'}} alt="logo" />
                )}
                <Typography sx={{
                    fontSize: {
                        xs: 36,
                        md: 42, 
                    },
                    fontWeight: 600,
                    fontStyle: 'italic',
                    margin: '0px', 
                    marginTop: '10px',
                    width: {
                        lg: '90%',
                        xl: 'auto'
                    }
                }}>
                    {data.banner.header}
                </Typography>
                {data.banner.subheader && (
                    <Typography variant="h2" sx={{
                        fontSize: {
                            xs: 24,
                            md: 32, 
                        },
                        fontWeight: 600,
                        margin: '0px', 
                        marginTop: '10px',
                        width: {
                            lg: '90%',
                            xl: 'auto'
                        }
                    }}>
                        {data.banner.subheader}
                    </Typography>
                )}
                <br />
                {data.banner.description.map((paragraph, index) => {
                    return (
                        <Typography variant="body1" key={index} sx={{
                            width: '100%',
                            // textIndent: 50,
                            fontSize: {
                                xs: 18,
                                sm: 24
                            },
                            marginBottom: '15px',
                            textAlign: 'left'
                        }}>
                            {paragraph.paragraph}
                        </Typography>
                    )
                })}
            </Box>
        </Box>
    );
}

export default DescriptionBox;
