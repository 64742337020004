import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useStateWithCallbackLazy } from 'use-state-with-callback';

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import NativeForms from 'native-forms-react';

import SignUpData from '../3CE/Signup.data.js'
import isProductionEnv from '../../helpers/isProductionEnv.js';

import CoachMessage from '../Styled Components/CoachMessage.js';
import EmailForm from './components/EmailForm.component.js';
import { CustomDialogTitle } from '../Styled Components/CustomDialogTitle.js';
import ProgressIndicator from './components/ProgressIndicator.component.js';

// Firebase imports
// import firebaseApp from '../firebase/FirebaseConfig.js';
// import { logEvent } from 'firebase/analytics';
// import { subscribeToAuthChanges } from '../firebase/FirebaseAuthService.js';
// import { createCoachingProfile, addSurveyToProfile, updateHubspotContact, updateIdentityFieldInProfile } from '../redux/CoachingProfile/CoachingProfile.slice.js';
// import { checkIfUserExists } from '../firebase/FirestoreService.js';

// const analytics = firebaseApp.analytics;


const SignUpDialog = (props) => {
    // const dispatch = useDispatch();
    
    const {
        dialogVisible,
        toggleDialogOpen,
        email,
        referral_source,
        handleEmailChange,
        customFinalCoachMessage
    } = props;
    const [currentStep, setCurrentStep] = useState(0);
    // const [currentUser, setCurrentUser] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailErrorState, setEmailErrorState] = useState(false);
    
    // Initialize local variables that will be communicated across multiple NativeForm form submissions
    // const identity = useSelector(state => state.coachingProfile.coachingProfile.identity);
    
    // subscribeToAuthChanges(setCurrentUser);

    // **********
    // TODO: CAN REMOVE THIS USEEFFECT ONCE DONE DIAGNOSTICS
    // **********
    // useEffect(() => {
    //     console.log('Coaching Signup App. Current user auth changed: ', currentUser);
    // }, [currentUser]);

    // Log an Analytics Event when the coaching dialog first opens, only in production env
    useEffect(() => {
        if (dialogVisible && currentStep===0) {
            if (isProductionEnv() === true) {
                // console.log('GALOG. Intro dialog is being shown. Log FIRST Analytics event here.');
                // logEvent(analytics, "select_content", {
                //     content_type: "coaching_signup_dialog_opened"
                // });
            }
        }
    }, [dialogVisible, currentStep]);


    const handleFormSubmit = async (event) => {
        
        if (currentStep === 0) {
            event.preventDefault(); // Prevent the page from refreshing when "Get Started" button is pressed on first screen.
            
            // Validate if the user entered a valid email address before letting them proceed.
            if (!email.match(/\S+@\S+\.\S+/)) {
                setEmailErrorState(true);
                return;
            }
            
            // Check if a user profile already exists with the email address entered.
            // const doesUserExist = await checkIfUserExists(email);
            // if (doesUserExist) {
            //     // console.log('User already exists. Blocked new account creation.');
            //     setCurrentStep(999);
            //     // alert("Looks like you already created an account before. Email your coach at hello@electrifyze.la");
            //     return;
            // }

            // Log an Analytics Event when the Get Started Button is clicked in first coaching dialog, only in production env
            if (isProductionEnv() === true) {
                // console.log('GALOG. Get Started Button clicked with valid email input. Log SECOND Analytics event here.');
                // logEvent(analytics, "select_content", {
                //     content_type: "coaching_signup_get_started_button_clicked",
                // });
            }
        
        } else if (currentStep === 1 ) {
            // Create user account and coaching profile
            // dispatch(createCoachingProfile({email, referral_source, event}));
            // Update local info on user's first and last name to pass into next sign-up steps
            setFirstName(event.formData["First Name"]);
            setLastName(event.formData["Last Name"]);
            
            
            // Log an Analytics Event when the first survey is submitted, only in production env
            if (isProductionEnv() === true) {
                // console.log(`GALOG. Survey #${currentStep} submitted. Log THIRD Analytics event here.`);
                // logEvent(analytics, "select_content", {
                //     content_type: `coaching_signup_survey_${currentStep}_submitted`,
                // });
            }
            
        } else if (currentStep > 1) {
            // dispatch(addSurveyToProfile({event, currentUser, currentStep}));
            
            // Log an Analytics Event when the 2nd-3rd-4th survey is submitted, only in production env
            if (isProductionEnv() === true) {
                // console.log(`GALOG. Survey #${currentStep} submitted. Log FOURTH-FIFTH-SIXTH Analytics event here.`);
                // logEvent(analytics, "select_content", {
                //     content_type: `coaching_signup_survey_${currentStep}_submitted`,
                // });
            }
            
            // if (currentStep === 3) {
            //     // Update Hubspot Profile with the user's phone number
            //     // if (event.formData["Phone Number"]) {
                    
            //     //     // const updateData = { phone: event.formData["Phone Number"] };
            //     //     // Update Hubspot contact
            //     //     // dispatch(updateHubspotContact({ currentUser, updateData }));
            //     //     // Update Firestore profile
            //     //     // dispatch(updateIdentityFieldInProfile({ currentUser, updateData }));
            //     // }
            // }
        }
        setCurrentStep(currentStep+1);
    }
    
    const renderCurrentStep = () => {
        if (currentStep === 0) {
            return (
                <React.Fragment>
                    <CoachMessage 
                        coachImage={SignUpData.coachImage}
                        message={SignUpData.intro} 
                    />
                    <EmailForm 
                        email={email}
                        handleEmailChange={handleEmailChange}
                        handleSubmit={handleFormSubmit}
                        colorSpecs={{
                            formColor: 'black',
                            buttonColor: 'black',
                            buttonHoverColor: 'rgba(0,0,0,0.7)',
                            buttonTextColor: 'white'
                        }}
                        buttonText='Get Started'
                        emailErrorState={emailErrorState}
                        bounceOn

                    />
                </React.Fragment>
            )
        } else if ( currentStep >= 1 && currentStep <= 4 ) {
            
            // TODO - THE SECOND CASE IN THE BELOW LINE OF CODE IS THE ANNOYING CULPRIT THAT'S TRIGGERING A RE-RENDER
            const formURLParams = 
                currentStep === 1 ?
                        `email=${email}&Referral=${referral_source}`
                    :
                        `email=${email}&name=${firstName} ${lastName}&Referral=${referral_source}&First Name=${firstName}&Last Name=${lastName}`
                ;

            const formUrlWithParams = `${SignUpData.StepData[currentStep-1].formLink}?${formURLParams}`;
            
            // console.log(formUrlWithParams);
            return (
                <React.Fragment>
                    <ProgressIndicator 
                        currentStep={currentStep-1}
                        numberOfSteps={SignUpData.StepData.length}
                    />
                    <CoachMessage 
                        coachImage={SignUpData.coachImage}
                        message={SignUpData.StepData[currentStep-1].coachingMessage}
                    />
                    <div style={{border: '1px solid black'}}>                    
                        <NativeForms key={currentStep}
                            form={formUrlWithParams}
                            onSend={handleFormSubmit}
                        />
                    </div>
                </React.Fragment>
            )
        } else if ( currentStep === 5 ) {
            // Show final screen
            return (
                <React.Fragment>
                    {
                        customFinalCoachMessage ?
                            <CoachMessage
                                coachImage={SignUpData.coachImage}    
                                message={customFinalCoachMessage}
                            />
                        :
                            <CoachMessage
                                coachImage={SignUpData.coachImage}
                                message={SignUpData.final}
                            />
                    }
                </React.Fragment>
            )
        } else if ( currentStep === 999 ) {
            // Show screen for user already signed up before
            return (
                <React.Fragment>
                    <CoachMessage message={SignUpData.userAlreadyExists} />
                </React.Fragment>
            )
        }
    }
    return (
        <Dialog 
            maxWidth='md'
            aria-labelledby="customized-dialog-title"
            open={dialogVisible}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={() => toggleDialogOpen('close')}>
                {SignUpData.dialogTitle}
            </CustomDialogTitle>
            <DialogContent dividers>
                {renderCurrentStep()}
            </DialogContent>
            
        </Dialog>
    );
};

export default SignUpDialog;