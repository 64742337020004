import React, { useLayoutEffect, useRef, useState } from 'react';

//Styles
// import { makeStyles, useTheme } from '@material-ui/styles';
// import { useMediaQuery } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import LazyLoad from 'react-lazyload';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';


// const useStyles = (height) => makeStyles(theme => ({
//     video: {
//         position: 'absolute',
//         objectFit: 'cover',
//         width: '100%',
//         height: height,
//         [theme.breakpoints.down(1280)]: {
//             display: 'none'
//         },
//     },
// }));

const TopBottomSloganText = styled(Typography)(({ theme }) => ({
    color: 'white',
    fontWeight: 500,
    fontSize: 42,
    [theme.breakpoints.down('md')]: {
        fontSize: 36,
        marginTop: 0
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: 24,
        marginBottom: 10
    },
}));

function MainImageBanner(props) {
    const { data, page } = props;

    const [height, setHeight] = useState()

    const mainBoxRef = useRef()
    const classes = {} // useStyles(height)(props)
    const theme = useTheme();
    const isVideoWidth = useMediaQuery(theme.breakpoints.up(1280));

    useLayoutEffect(() => {
        if (mainBoxRef.current) {
            setHeight(mainBoxRef.current.offsetHeight)
        }
    }, [mainBoxRef]);

    return (
        <React.Fragment>
            {data.video && isVideoWidth && (
                <LazyLoad
                    className={classes.lazyLoad}
                    once
                >
                    <video autoPlay muted loop id="myVideo" className={classes.video}>
                        <source src={data.video} type="video/mp4" />
                    </video>
                </LazyLoad>
            )}

            <Box sx={{
                padding: {
                    xs: '20px',
                    sm: '60px',
                    md: '120px'
                },
                maxWidth: {
                    xs: 'calc(100% - 40px)',
                    sm: 'calc(100% - 120px)',
                    md: 'calc(100% - 240px)'
                },
                backgroundSize: "cover", 
                backgroundPosition: "center center"
            }} ref={mainBoxRef} style={{backgroundImage: 'url(' + data.mainImage + ')', backgroundPosition: 'center'}}>       
                <Box sx={{
                    position: 'relative',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    maxWidth: 960,
                    margin: '0 auto',
                    padding: '40px',
                    textAlign: 'center',
                    width: {
                        xs: '85%',
                        sm: '70%',
                        md: '55%'
                    },
                    fontSize: {
                        xs: 32,
                        sm: 38,
                    },
                    marginTop: {
                        xs: '0px',
                        md: 'auto'
                    }
                }}>
                    {data.topSlogan && (
                        <TopBottomSloganText variant="h2">
                            {data.topSlogan}
                        </TopBottomSloganText>
                    )}
                    <Typography variant="h1" sx={{
                        color: 'white',
                        fontWeight: 500,
                        fontSize: {
                            xs: 40,
                            sm: 60,
                            md: 85
                        },
                        // [theme.breakpoints.down('md')]: {
                        //     fontSize: 60,
                        //     marginTop: 0
                        // },
                        // [theme.breakpoints.down('xs')]: {
                        //     fontSize: 40,
                        //     marginBottom: 10
                        // },
                    }}>
                        {data.slogan}
                    </Typography>
                    {data.bottomSlogan && (
                        <TopBottomSloganText variant="h2">
                            {data.bottomSlogan}
                        </TopBottomSloganText>
                    )}
                    {data.bulletedText && (
                        <ul style={{textAlign: 'left', color: 'white', marginTop: 30, marginBottom: 0}}>
                            {data.bulletedText.map((text, index) => (
                                <li key={index}>
                                    <Typography variant="h6">
                                        {text}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    )}
                    {data.customBox && (
                        data.customBox
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default MainImageBanner;
