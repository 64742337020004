import React, { } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Styles
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function PageBreakContent(props) {
    const { content } = props;

    return (
        <Box sx={{
            width: '100%', 
        }}>
            <Box sx={{
                maxWidth: 960,
                margin: '0 auto',
                marginTop: '40px', 
                marginBottom: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginLeft: {
                    xs: '10%',
                    md: 'auto'
                },
                marginRight: {
                    xs: '10%',
                    md: 'auto'
                },
            }}>
                {content}
            </Box>
        </Box>
    );
}

export default PageBreakContent;
