import React from "react";

import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const StyledTaskButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    borderRadius: 10,
    padding: 15,
    height: 50,
    border: "2px solid black",
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: 3,
        paddingBottom: 3,
    },
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    "&:disabled": {
        border: "2px solid rgba(0, 0, 0, 0.4)",
    },
}));

export const StyledGoldButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    border: "1px solid black",
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    textTransform: "none",
}));

export const StyledBlackActionButton = styled(Button)(({ theme }) => ({
    backgroundColor: "black",
    color: "white",
    //height: 70,
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .7)",
    },
    padding: 30,
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down('xl')]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    fontSize: 32,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 24,
    },
}));

export const StyledBlackButton = styled(Button)(({ theme }) => ({
    backgroundColor: "black",
    color: "white",
    //height: 50,
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .7)",
    },
    padding: 15,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
}));

export const StyledBlackOutlineButton = styled(Button)(({ theme }) => ({
    backgroundColor: "white",
    border: "2px solid black",
    color: "black",
    height: 50,
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    padding: 15,
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: 3,
        paddingBottom: 3,
    },
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
}));

export const StyledBlackOutlineButtonSmall = styled(Button)(({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid black",
    color: "black",
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    textTransform: "none",
}));

export const StyledBlueButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#36c1d8",
    color: "black",
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "#36c1d870",
    },
    padding: 15,
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: 20,
    fontWeight: 600,
    textTransform: "none",
}));

export const RoundedGoldButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    height: 50,
    borderRadius: 25,
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    padding: 15,
    paddingLeft: 20,
    paddingRight: 20,
    border: "2px solid black",
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
}));

export const GoldCircleButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    height: 50,
    minWidth: 50,
    borderRadius: 25,
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    padding: 0,
    border: "2px solid black",
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    whiteSpace: "nowrap",
}));

const StyledArrowButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    minWidth: 50,
    height: 50,
    borderRadius: 25,
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    padding: 0,
    fontSize: 24,
    fontWeight: 600,
    width: "auto",
}));

export const StyledNextButton = ({ color, ...props }) => (
    <StyledArrowButton {...props}>
        <FontAwesomeIcon
            icon={faArrowRight}
            color={color ? color : "black"}
            size={"lg"}
        />
    </StyledArrowButton>
);

export const StyledBackButton = ({ color, ...props }) => (
    <StyledArrowButton {...props}>
        <FontAwesomeIcon
            icon={faArrowLeft}
            color={color ? color : "black"}
            size={"lg"}
        />
    </StyledArrowButton>
);
