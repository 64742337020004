//Styles
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const GrayTextBox = styled(Typography)(({ theme }) => ({
    backgroundColor: "gainsboro",
    padding: 15,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: "left",
}));