import React from 'react';

//Styles
// import { makeStyles } from '@material-ui/styles';

// Components
import Footer from '../Styled Components/Footer';
import MainImageBanner from './MainImageBanner';
import DescriptionBox from './DescriptionBox';
import RaisedTiles from './RaisedTiles';
import StyledPageBreak from './StyledPageBreak'
import PageBreakContent from './PageBreakContent'

// const useStyles = makeStyles(theme => ({
//     root: {
//         position: 'relative', 
//         margin: 0, 
//         padding: 0, 
//         overflow: 'auto', 
//         minHeight: 'calc(100vh - 114px)'
//     },
//     footerImageBanner: {
//         marginTop: 40
//     },
//     footerBox: {
//         position: 'relative', 
//         width: '100%', 
//         bottom: 0
//     },
//     carouselBox: {
//         maxWidth: 960, 
//         margin: '0 auto', 
//         marginTop: 40, 
//         marginBottom: 60, 
//         border: '5px solid black', 
//         boxShadow: '-20px 20px 0px 0px rgba(0,0,0,0.5)', 
//         padding: 20,
//         [theme.breakpoints.down('sm')]: {
//             marginLeft: 25,
//             marginRight: 25
//         },
//     }
// }));

function FormattedHomePage(props) {
    const classes = {} // useStyles()
    const { data, page, handleStartClick, handleTryClick } = props;

    return (
        <>
            <div>
                <div className={classes.root}>
                    
                    <MainImageBanner data={data} page={page} />

                    <DescriptionBox data={data} />

                    {data.content.length === 0 ?
                        null
                    :
                        data.content.map((row, index) => {
                            if (row.pageBreak) {
                                return (
                                    <div key={index} style={{marginTop: 40}}>
                                        <StyledPageBreak title={row.pageBreak.title} />
                                        {row.pageBreak.content && (
                                            <PageBreakContent content={row.pageBreak.content} />
                                        )}
                                    </div>
                                )
                            } 
                            return <RaisedTiles text={row.text} image={row.image} button={row.button} buttons={row.buttons} index={index} key={index} />
                        })
                    }
                </div>
                <div className={classes.footerBox}>
                    <Footer displayForm={false} handleTryClick={handleTryClick} handleStartClick={handleStartClick} />
                </div>
            </div>
        </>

    );
}

export default FormattedHomePage;
