import React from 'react';
import clsx from 'clsx';

// import { makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';


const useStyles = (colorSpecs) => makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'  
    },
    emailForm: {
        justifyContent: 'center',
        margin: '20px 10px'
    },
    emailField: {
        '& label': {
            color: colorSpecs.formColor
        },
        '& label.Mui-focused': {
            color: colorSpecs.formColor
        },
        // 2022-02-15: Had to disable the use of .MuiInput*, because the color specs were not working when embedded as an NPM package in a parent app. :(
        // '& .MuiInput-underline:before': {
        //     borderBottomColor: colorSpecs.formColor
        // },
        // '& .MuiInput-underline:hover:not($disabled):before': {
        //     borderBottomColor: colorSpecs.formColor
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: colorSpecs.formColor
        // },
        '& input': {
            color: colorSpecs.formColor,
            "border-bottom": `1px solid ${colorSpecs.formColor}`,
            '&:hover': {
                color: colorSpecs.formColor,
                "border-bottom": `2px solid ${colorSpecs.formColor}`,
            },
            '&:focus': {
                color: colorSpecs.formColor,
                "border-bottom": `2px solid ${colorSpecs.formColor}`,
            },
            transition: ['border-bottom'],
            transitionDuration: 200,
        },
        width: '400px',
        marginRight: '20px',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10,
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            marginBottom: 10,
            marginRight: 0
        },
    },
    getStartedButton: {
        backgroundColor: colorSpecs.buttonColor,
        color: colorSpecs.buttonTextColor,
        borderRadius: 10,
        padding: 15,
        paddingTop: 5,
        paddingBottom: 5,
        border: '2px solid black',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 3,
            paddingBottom: 3,
        },
        '&:hover': {
            backgroundColor: colorSpecs.buttonHoverColor
        },
        '&:disabled': {
            border: '2px solid rgba(0, 0, 0, 0.4)',
        },
        fontSize: 24,
        fontWeight: 600,
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        },
    },
    bounce: {
        animation: `$bounce 3s ${theme.transitions.easing.easeInOut} infinite`,
    },
    '@keyframes bounce': {
        '0%, 33%, 7%, 17%, 27%': {
            webkitTransform: 'translateY(0)',
            msTransform: 'translateY(0)',
            transform: 'translateY(0)'
        },
        '13%': {
            webkitTransform: 'translateY(-30px)',
            msTransform: 'translateY(-30px)',
            transform: 'translateY(-30px)'
        },
        '20%': {
            webkitTransform: 'translateY(-15px)',
            msTransform: 'translateY(-15px)',
            transform: 'translateY(-15px)'
        }
    }
}));

const EmailForm = (props) => {
    const {email, emailErrorState, handleEmailChange, handleSubmit, colorSpecs, buttonText, bounceOn, autoFocus, inputRef } = props;
    const classes = useStyles(colorSpecs)();
    
    
    return (
        <div className={classes.container} >
            <Grid item container className={classes.emailForm}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        name='email'
                        label='ENTER EMAIL' 
                        autoFocus={autoFocus}
                        inputRef={inputRef} // Used for allowing autofocus on scroll events
                        variant='standard' 
                        value={email}
                        onChange={handleEmailChange} 
                        className={classes.emailField}
                        InputProps={{ disableUnderline: true }} // Disables the use of MuiInput-underline, as it was not working as an NPM package in a parent app
                        required
                        error={emailErrorState} // Should be true or false
                        helperText={emailErrorState ? 'Enter a valid email address to get started...' : null}
                    />
                    <Button 
                        className={clsx(classes.getStartedButton, bounceOn && classes.bounce)}
                        type='submit'
                    >
                        {buttonText}
                    </Button>
                </form>
            </Grid>
        </div>

    );
};

export default EmailForm;