import React from "react";
// import NativeForms from "native-forms-react";
import { useLocation } from "react-router-dom";

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography, Grid, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

//Components
import { CustomDialogTitle } from "../Styled Components/CustomDialogTitle";
import CoachMessage from "../Styled Components/CoachMessage";
import { StyledTaskButton } from "../Styled Components/Buttons";

//Assets
import Earth from "../../static/images/earth-only.png";

//Helpers
import { paramsToObject } from "../../helpers/functions";


const BouncingTaskButton = styled(StyledTaskButton)(( { theme } ) => ({
    marginTop: 15,
    marginBottom: -7,
    "@keyframes bounce": {
        "0%, 33%, 7%, 17%, 27%": {
            webkitTransform: "translateY(0)",
            msTransform: "translateY(0)",
            transform: "translateY(0)",
        },
        "13%": {
            webkitTransform: "translateY(-30px)",
            msTransform: "translateY(-30px)",
            transform: "translateY(-30px)",
        },
        "20%": {
            webkitTransform: "translateY(-15px)",
            msTransform: "translateY(-15px)",
            transform: "translateY(-15px)",
        },
    },
    animation: `bounce 2.5s ${theme.transitions.easing.easeInOut} infinite`,
}));


function CampaignLaunchNewWindowDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = {} // useStyles();
    const { open, toggleDialog } = props;

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    let referralEmail = params.referral;

    const demoCampaignURL = `https://learn.electrifyze.com/sample-campaign/campaign/?referral=3CE-${referralEmail || ''}`;
   

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onBackdropClick={toggleDialog}
            classes={{ paper: classes.dialogPaper }}
        >
            <CustomDialogTitle onClose={toggleDialog}>
                Try it out… Get a sneak preview of <b>EVs For Earth</b>
            </CustomDialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.dialogBox}>
                    <CoachMessage
                        coachImage={Earth}
                        message={
                            <div>
                                <Typography
                                    variant="h5"
                                    align="center"
                                >
                                    <b>
                                        Onwards... to the Sneak Preview!
                                    </b>
                                </Typography>
                                <Box m={2.0} />
                                <Typography
                                    variant="h5"
                                    // className={classes.messageText}
                                >
                                    From here, you'll see things from the perspective of your audience.
                                </Typography>
                                <Box m={2.0} />
                                <Grid item xs container justifyContent="center">
                                    <BouncingTaskButton 
                                        // className={classes.bounce} 
                                        onClick={() => window.open(demoCampaignURL, "_blank")}
                                    >
                                        Launch the Sneak Preview
                                    </BouncingTaskButton>
                                </Grid>
                                <Box m={1.0} />
                                <Typography
                                    variant="body2"
                                    // className={classes.messageText}
                                    align="center"
                                >
                                    ( Preview will launch in a new window )
                                </Typography>
                            </div>
                        }
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CampaignLaunchNewWindowDialog;
