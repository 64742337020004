import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Pulled directly from Firebase dashboard
const firebaseConfig = {
    apiKey: "AIzaSyAfSR2-iJsZj9j0ADXZ3duGgBWD5Fv1ZSA",
    authDomain: "lets-electrifyze-us.firebaseapp.com",
    projectId: "lets-electrifyze-us",
    storageBucket: "lets-electrifyze-us.appspot.com",
    messagingSenderId: "198589841086",
    appId: "1:198589841086:web:bcd62a78982ea8b9c6a8ef",
    measurementId: "G-YWG1JBP4P9"
};

//Initialize firebase
initializeApp(firebaseConfig);
getAnalytics();

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
