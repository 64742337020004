import React from 'react';
// import parse from 'html-react-parser';

//Styles
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import LazyLoad from 'react-lazyload';

const useStyles = makeStyles(theme => ({
    flexBox: {
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    grayBox: {
        backgroundColor: '#404040', 
        color: 'white'
    },
    box: {
        margin: 40,
        height: 420,
        boxShadow: '-20px 20px 0px 0px rgba(0,0,0,0.5)',
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        },
    },
    imageBox: {
        margin: 40,
        height: 420,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            //display: 'none'
            height: 300,
        },
        [theme.breakpoints.down('xs')]: {
            //display: 'none'
            height: 200,
        },  
    },
    innerBox: {
        height: '100%',
        border: '5px solid black',
        padding: 20,
    },
    image: {
        height: '100%', 
        width: '100%', 
        objectFit: 'cover'
    },
    blackTopBar: {
        height: 5, 
        width: 100, 
        backgroundColor: 'black', 
        marginBottom: 10,
        [theme.breakpoints.down('md')]: {
            height: 4, 
        },
    },
    whiteTopBar: {
        height: 5, 
        width: 100, 
        backgroundColor: 'white', 
        marginBottom: 10,
        [theme.breakpoints.down('md')]: {
            height: 4, 
        },
    },
    heading: {
        fontSize: 40, 
        fontWeight: 600,
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            fontSize: 32
        },
    },
    subheading: {
        fontSize: 32, 
        marginTop: 5, 
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 30, 
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 24
        },
    },
    description: {
        fontSize: 24,
        [theme.breakpoints.down('lg')]: {
            fontSize: 20
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 18
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        },
        marginBottom: 20,
        //fontFamily: 'Roboto'
    },
    buttonContainer: {
        height: '100%'
    },
    lazyLoad: {
        display: 'flex',
        alignItems: 'center'
    }
}));

function RaisedTiles(props) {
    const classes = useStyles();
    const { button, buttons } = props;

    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    });

    const updateWidth = () => {
        setWidth(window.innerWidth);
    };


    return (
        <div>
            <Grid container alignItems="center" className={classes.flexBox}>
                {(props.index % 2 !== 0 || (props.index % 2 === 0 && width < 960)) && ( //Check whether image will displayed on the left or right - evens on the left
                    <Grid item xs className={classes.imageBox}>
                        <LazyLoad
                            className={classes.lazyLoad}
                            once
                        >
                            <img className={classes.image} src={props.image} alt="tile"/>
                        </LazyLoad>
                    </Grid>
                )}

                <Grid item xs className={classes.box}>
                    <Grid container direction="column" className={classes.innerBox}>
                        <Grid item xs style={{height: 'auto'}}>
                            <Typography variant="h3" className={classes.heading}>
                                {props.text.header}
                            </Typography>
                            {props.text.subheading && (
                                <Typography variant="h6" className={classes.subheading}>
                                    {props.text.subheading}
                                </Typography>
                            )}
                            <br />
                            {props.text.description.map((paragraph, index) => {
                                return (
                                    <Typography variant="body1" className={classes.description} key={index}>
                                        {paragraph.paragraph}
                                    </Typography>
                                )
                                
                                // return (
                                //     <Typography variant="body1" className={classes.description} key={index}>
                                //         {paragraph.paragraph ?
                                //             paragraph.paragraph
                                //         :
                                //             parse(paragraph.html)
                                //         }
                                //     </Typography>
                                // )
                            })}
                        </Grid>
                        <Grid item>
                            {(button || buttons) && (
                                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                    {button || buttons}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                
                
                {props.index % 2 === 0 && width >= 960 && (
                    <Grid item xs className={classes.imageBox}>
                        <LazyLoad 
                            className={classes.lazyLoad}
                            once
                        >
                            <img className={classes.image} src={props.image} alt="tile"/>
                        </LazyLoad>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export default RaisedTiles;
