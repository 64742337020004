import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import FormattedHomePage from "../FormattedHomePage/FormattedHomePage";
import Header from "../Styled Components/Header";
import { StyledBlackOutlineButton, StyledTaskButton } from '../Styled Components/Buttons';
import CampaignLaunchNewWindowDialog from './CampaignLaunchNewWindowDialog';

// import SignUpDialog from "./SignUpDialog";
import SignUpRoot from "../MultiFormSignUp/SignUpRoot.component";

// Styles
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

// Assets
// import LASkyline from '../../static/images/la-skyline.jpg'
import ElectrifyzeMultiScreenshots from '../../static/images/electrifyze-multi-screenshots.png';
import ElectrifyzeQuizScorecard from '../../static/images/electrifyze-quiz-scorecard.png';
import ElectrifyzeCoachOverview from '../../static/images/electrifyze-coach-overview.png';
import ElectrifyzeFossilFuelFree from '../../static/images/electrifyze-fossil-fuel-free.png';
import EVCoastCrop from '../../static/images/EV-Coast-crop.jpg'
import ElectrifyzeLogoBlack from '../../static/images/Electrifyze-Logo-black.svg'
import CCCELogo from '../../static/images/CCCE-logo.png'
import CECLogo from '../../static/images/CEC-logo.png'
import EALogo from '../../static/images/EA-logo.png'
import Footer from "../Styled Components/Footer";
import CampaignIntroDialog from "./CampaignIntroDialog";
import CampaignIntroSlides from "./CampaignIntroSlides";



function Main() {
    // Set up to launch the "Sign Up" dialog if a "signup" param exists in URL query params
    let [URLQueryParams] = useSearchParams();
    const openSignUpDialogOnStart = URLQueryParams.get("signup");
    let openSignUpDialogOnStart_cleaned = false;
    if (openSignUpDialogOnStart) {
        openSignUpDialogOnStart_cleaned = true;
    };

    // Initialize state variables
    const [open, toggleDialog] = useState(openSignUpDialogOnStart_cleaned)
    const [openCampaignIntroDialog, toggleCampaignIntroDialog] = useState(false)
    const [openCampaignLaunchNewWindowDialog, toggleCampaignLaunchNewWindowDialog] = useState(false)
    const [displayIntroSlides, setDisplayIntroSlides] = useState(false)
    const navigate = useNavigate()

    const handleStartClick = () => {
        toggleDialog(!open)
    }

    const handleTryClick = () => {
        toggleCampaignIntroDialog(true)
    }

    const handleHomeClick = () => {
        // navigate("/3ce") // TO DO: Need to make sure that URL search params don't get wiped

        if (displayIntroSlides) {
            setDisplayIntroSlides(false)
        }
    }

    const data = {
        topSlogan: "Helping YOU Create",
        slogan: "A Fossil Fuel Free Central Coast",
        mainImage: EVCoastCrop,
        banner: {
            // logo: ElectrifyzeLogoBlack,
            header: 'EVs For Earth',
            subheader: 'Help Your Audience Go Electric',
            description: [
                {
                    paragraph: (
                        <div>
                            <b>EVs For Earth</b> is a regional campaign for Earth Day 2022. It helps your organization drive the adoption of electric vehicles (EVs) across your audience. Whether your audience is your employees, your followers, your friends, or anyone else, EVs for Earth will help!
                        </div>
                    )
                },
                {
                    paragraph: "We handle all the hard work of engaging and educating people about EVs, in a fun way… And for those who are in-market, we help them through the buying process!"
                },
                {
                    paragraph: "We make it easier than ever for you or your organization to be the climate warriors that create a fossil fuel free community."
                },
                {
                    paragraph: (
                        <div>
                            EVs for Earth is sponsored by Central Coast Community Energy, and powered by Electrifyze. It is <b style={{color: 'red'}}>FREE for your organization to participate.</b>
                        </div>
                    )
                },
                {
                    paragraph: (
                        <Grid container justifyContent="center" style={{marginTop: 10}}>
                            <StyledBlackOutlineButton sx={{marginRight: 2}} onClick={handleTryClick}>
                                Try it Out
                            </StyledBlackOutlineButton>
                            <StyledTaskButton onClick={handleStartClick}>
                                Get Started
                            </StyledTaskButton>
                        </Grid>
                    )
                },
            ]
        },
        content: [
            {
                pageBreak: {
                    title: "Electrify Your Audience",
                    content: (
                        <div>
                            <Typography sx={{
                                fontSize: {
                                    xs: 18,
                                    sm: 24
                                },
                            }}>
                                <b>EVs for Earth</b> will hold <b>virtual contests</b> for your audience to learn about electric cars, 
                                their benefits, and how to save thousands of dollars while getting one. 
                                Our contests are <b style={{color: 'red'}}>FREE to participate</b> – 
                                all you need to do is spread the word through your existing channels.
                            </Typography>
                        </div>
                    )
                }
            },
            {
                text: {
                    header: "Make EV Learning FUN",
                    description: [
                        {
                            paragraph: "Your audience will learn about electric cars in a fun, interactive way, that feels like a game…"
                        },
                        {
                            paragraph: "Thousands of past participants describe our contests as a fun, engaging, and informative way to learn about EVs."
                        }
                    ]
                },    
                image: ElectrifyzeMultiScreenshots,
                buttons: [
                    (
                        <StyledBlackOutlineButton sx={{marginRight: 2}} onClick={handleTryClick}>
                            Try it Out
                        </StyledBlackOutlineButton>
                    ),
                    (
                        <StyledTaskButton onClick={handleStartClick}>
                            Get Started
                        </StyledTaskButton>
                    )
                ]
            },
            {
                text: {
                    header: "Earn Points. Win Prizes!",
                    description: [
                        {
                            paragraph: "Participants earn points while completing activities that teach them about electric cars. More points = more chances to win."
                        },
                        {
                            paragraph: "There are huge prizes up for grabs for contest participants!"
                        }
                    ]
                },    
                image: ElectrifyzeQuizScorecard,
                buttons: [
                    (
                        <StyledBlackOutlineButton sx={{marginRight: 2}} onClick={handleTryClick}>
                            Try it Out
                        </StyledBlackOutlineButton>
                    ),
                    (
                        <StyledTaskButton onClick={handleStartClick}>
                            Get Started
                        </StyledTaskButton>
                    )
                ]
            },
            {
                text: {
                    header: "EV Car Buying Coach",
                    description: [
                        {
                            paragraph: "We automatically identify participants who are in-market to buy a car."
                        },
                        {
                            paragraph: "In-market buyers get a FREE Car Buying Coach, that helps them go electric and claim thousands of dollars in incentives."
                        }
                    ]
                },    
                image: ElectrifyzeCoachOverview,
                buttons: [
                    (
                        <StyledBlackOutlineButton sx={{marginRight: 2}} onClick={handleTryClick}>
                            Try it Out
                        </StyledBlackOutlineButton>
                    ),
                    (
                        <StyledTaskButton onClick={handleStartClick}>
                            Get Started
                        </StyledTaskButton>
                    )
                ]
            },
            {
                pageBreak: {
                    title: "Lead By Example… With Ease.",
                    content: (
                        <div>
                            <Typography sx={{
                                fontSize: {
                                    xs: 18,
                                    sm: 24
                                },
                            }}>
                                Sponsored by Central Coast Community Energy, the <b>EVs For Earth</b> team works to make it EASY for you to help your audience go fossil fuel free.
                            </Typography>
                        </div>
                    )
                }
            },
            {
                text: {
                    header: "Turnkey EV Adoption.",
                    description: [
                        {
                            paragraph: "Before campaigns, the EVs For Earth team helps you promote upcoming campaigns to your audience.",
                        },
                        {
                            paragraph: "After campaigns, the EVs For Earth team provides key metrics to highlight your organization’s climate leadership."
                        },
                    ]
                },    
                image: ElectrifyzeFossilFuelFree,
                buttons: [
                    (
                        <StyledBlackOutlineButton sx={{marginRight: 2}} onClick={handleTryClick}>
                            Try it Out
                        </StyledBlackOutlineButton>
                    ),
                    (
                        <StyledTaskButton onClick={handleStartClick}>
                            Get Started
                        </StyledTaskButton>
                    )
                ]
            },
            {
                pageBreak: {
                    title: "Creating a Fossil Fuel Free Central Coast",
                    content: (
                        <div>
                            <Typography sx={{
                                fontSize: {
                                    xs: 18,
                                    sm: 24
                                },
                            }}>
                                <b>EVs For Earth</b> is brought to you by Electrifyze, in partnership with Ecology Action and the Community Environmental Council. The program is sponsored by Central Coast Community Energy.
                            </Typography>
                            <Box m={2.0} />
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                                    <img src={ElectrifyzeLogoBlack} style={{maxWidth: 250, height: 'auto'}} alt="logo" />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                                    <img src={CCCELogo} style={{maxWidth: 250, height: 'auto'}} alt="logo" />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                                    <img src={EALogo} style={{maxWidth: 250, height: 'auto'}} alt="logo" />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{textAlign: "center"}}>
                                    <img src={CECLogo} style={{maxWidth: 250, height: 'auto'}} alt="logo" />
                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            },
        ]
    }

    const handleBeginCampaignClick = () => {
        toggleCampaignIntroDialog(false)
        setDisplayIntroSlides(true)
    }

    const handleStartCampaignClick = () => {
        toggleCampaignLaunchNewWindowDialog(true)
    }

    return (
        <>
            <Header handleHomeClick={handleHomeClick} handleTryClick={handleTryClick} />

            {
            displayIntroSlides ?
                <CampaignIntroSlides startCampaign={handleStartCampaignClick} />
            :
                <FormattedHomePage data={data} handleTryClick={handleTryClick} handleStartClick={handleStartClick} />
            }

            {open && (
                // <SignUpDialog open={open} toggleDialog={handleStartClick} />
                <SignUpRoot openDialogOnStart={open} />
            )}

            {openCampaignIntroDialog && (
                <CampaignIntroDialog open={openCampaignIntroDialog} toggleDialog={() => {toggleCampaignIntroDialog(!openCampaignIntroDialog)}} handleBeginCampaignClick={handleBeginCampaignClick} />
            )}
            
            {openCampaignLaunchNewWindowDialog && (
                <CampaignLaunchNewWindowDialog open={openCampaignLaunchNewWindowDialog} toggleDialog={() => {toggleCampaignLaunchNewWindowDialog(!openCampaignLaunchNewWindowDialog)}} />
            )}

        </>
    );
}

export default Main;
