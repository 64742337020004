import React from 'react';

//Styles
// import { makeStyles } from '@material-ui/styles';

//Assets 
import ElectrifyzeLogoWhite from '../../static/images/Electrifyze-Logo-white.svg';

//Components
import Grid from '@mui/material/Grid';
import { ButtonBase, Typography } from '@mui/material';
import { StyledBlackOutlineButton, StyledTaskButton } from './Buttons';

// const useStyles = makeStyles(theme => ({
//     footerNavBox: {
//         flex: 6,
//         display: 'flex',
//         marginLeft: 20
//     },
//     logoImageBox: {
//         height: 80, 
//         width: 250, 
//         display: 'flex', 
//         alignItems: 'center', 
//         justifyContent: 'center', 
//         marginTop: 5
//     },
//     countyLogoImageBox: {
//         height: 50, 
//         width: 50, 
//         display: 'flex', 
//         alignItems: 'center', 
//         justifyContent: 'center', 
//         marginRight: 10
//     },
//     countyText: {
//         color: 'white',
//         textDecoration: 'none !important',
//         fontFamily: 'Quicksand',
//         fontWeight: 500
//     },
//     countyDescriptionText: {
//         [theme.breakpoints.down('xs')]: {
//             fontSize: 9
//         },
//     }
// }));

function Footer(props) {
    const { handleStartClick, handleTryClick } = props
    const classes = {} // useStyles(props)

    return (
        <footer style={{
            height: 90,
            display: 'flex',
            backgroundColor: 'black', 
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                height: '100%',
                width: '100%', 
                display: 'flex'
            }}>
                <div style={{
                    height: '100%',
                    flex: 3, 
                    msFlex: '3 0 auto',
                    paddingLeft: 20,
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <div style={{width: '100%'}}>
                        <Typography sx={{
                            lineHeight: 1.2,
                            color: 'white',
                            fontSize: {
                                xs: 24,
                                sm: 32
                            },
                        }}>
                            <b><i>EVs For Earth</i></b>
                        </Typography>
                        <Typography variant="h5" sx={{
                            width: '100%',
                            color: 'white', 
                            fontSize: 21, 
                            margin: 0, 
                            fontWeight: 400,
                            fontSize: {
                                xs: 10,
                                sm: 16,
                            }
                        }}>
                         &copy; 2022 Green Light Labs
                        </Typography>
                    </div>
                </div>
                <div style={{
                    height: '100%',
                    flex: 2, 
                    msFlex: '2 0 auto',
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: 25
                }}>
                    <Grid 
                        container 
                        style={{height: '100%'}} 
                        alignItems="center" 
                        justifyContent="flex-end"
                        sx={{display: {
                            xs: 'none',
                            sm: 'flex'
                        }}} 
                    >
                        <Grid item xs>
                            
                        </Grid>
                        <Grid item xs="auto" container alignItems="center">
                            <ButtonBase
                                onClick={handleTryClick}
                                style={{
                                    textAlign: "center",
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                <Typography variant="h6" style={{
                                    marginLeft: 20,
                                    fontWeight: 400,
                                }}>
                                    Try it Out
                                </Typography>
                            </ButtonBase>
                            <Typography variant="h5" sx={{
                                margin: 3,
                                fontWeight: 300,
                                color: "white",
                            }}>
                                |
                            </Typography>
                            <StyledTaskButton onClick={handleStartClick}>
                                Get Started
                            </StyledTaskButton>
                            {/* <Typography
                                variant="body1"
                                sx={{
                                    color: "white",
                                    textDecoration: "none !important",
                                    textAlign: 'right',
                                    fontWeight: 500,
                                }}
                            >
                                Brought to you by Central Coast Community Energy
                                <br />
                                Powered by Green Light Labs.
                            </Typography> */}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </footer>
    );
}

export default Footer;