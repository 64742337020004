import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import {
    StyledNextButton,
    StyledBackButton,
} from "../Styled Components/Buttons";

//Utils
import imageMapResize from "image-map-resizer";

// const useStyles = makeStyles((theme) => ({

//     titleGridItem: {
//         [theme.breakpoints.down('xl')]: {
            
//         },
//         [theme.breakpoints.down('lg')]: {
//             display: "none",
//         },
//     },
//     linearProgressGridItem: {},
//     rightButtonsGridItem: {
//         [theme.breakpoints.down('xl')]: {
//             maxWidth: "max-content",
//         },
//     },
//     nextBackButton: {
//         margin: 20,
//         [theme.breakpoints.down('xl')]: {
//             margin: 10,
//         },
//     },
// }));

const content = [
    'https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FSlide1.yhsnBB0D6emWIs0OMuIg.jpg?alt=media&token=c7c42d1d-2a04-4aca-85c0-902328b4723e',
    'https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FSlide2.xhmZRocjO7VrLzDlToj5.jpg?alt=media&token=f6779d1c-5483-4023-8614-0c002e5b137e',
    'https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FSlide3.YAsaQqTCXBTjrPrrWf51.jpg?alt=media&token=b14dd946-3bed-41c0-81e8-e0ee395d3464',
    'https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FSlide4.uiPAIWWJy8wrnM7qKgUd.jpg?alt=media&token=99d9fe4b-b3f5-4155-8d77-737a37748a36',
    'https://firebasestorage.googleapis.com/v0/b/electrifyze-fce77.appspot.com/o/flamelink%2Fmedia%2FSlide5.z3CvhTeFEpeyNTdBJD6s.jpg?alt=media&token=214c5887-f3e2-4c47-93ca-74370e6821c9'
]

function CampaignIntroSlides(props) {
    const { startCampaign } = props;
    const classes = { } // useStyles(props);
    const [activeStep, setActiveStep] = useState(0);
    const steps = content.length

    useEffect(() => {
        imageMapResize();
    }, [activeStep]);

    const handleNext = () => {
        if (activeStep !== steps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            startCampaign()
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div
            style={{
                height: "calc(100vh - 90px)",
                flex: "1 1 auto",
                display: "flex",
                flexDirection: "column",
                outline: "none",
            }}
            autoFocus
            tabIndex={0}
        >
            <Grid
                container
                direction="column"
                sx={{
                    flex: "1 1 auto",
                    height: "100%",
                    width: "100%",
                }}
            >
                <Grid
                    item
                    xs
                    container
                    alignItems="center"
                    style={{ padding: 20, overflowY: "auto" }}
                >
                    {content.map((item, index) => {
                        //We have to map all of them to start otherwise the image map won't work for each slide
                        return (
                            <div
                                key={index}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    display:
                                        index === activeStep ? "block" : "none",
                                }}
                            >
                                <div style={{
                                    flex: "1 1 auto",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}>
                                    <img
                                        src={item}
                                        style={{
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            msFlex: "0 0 auto",
                                        }}
                                        useMap={"#workmap"}
                                        alt="slide-map"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Grid>
                {/* Do not fix the position of this grid item. It allows perfect dynamic sizing of top grid item as is --> , position: 'fixed', bottom: 0}}> */}
                <Grid
                    item
                    container
                    alignItems="center"
                    style={{
                        height: 100,
                        backgroundColor: "black",
                        color: "white",
                    }}
                >
                    <Grid
                        item
                        xs
                        container
                        alignItems="center"
                        sx={{
                            maxWidth: "max-content",
                            marginRight: '5px',
                        }}
                    >
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                style={{
                                    textTransform: "capitalize",
                                    marginLeft: '20px',
                                }}
                            >
                                Try Out a Campaign
                            </Typography>
                        </Grid>
                    </Grid>

                    {activeStep !== 0 && (
                        <Grid
                            item
                            style={{ width: "auto" }}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <StyledBackButton
                                className={classes.nextBackButton}
                                onClick={handleBack}
                                style={{margin: 20}}
                            />
                        </Grid>
                    )}

                    <Grid
                        item
                        xs
                        className={classes.linearProgressGridItem}
                        style={activeStep === 0
                                ? { marginLeft: 10 }
                                : {}
                        }
                    >
                        <div style={{ position: "relative", bottom: 14 }}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <LinearProgress
                                    variant="determinate"
                                    value={((activeStep + 1) / steps) * 100}
                                    sx={{
                                        height: 30,
                                        borderRadius: 15,
                                        backgroundColor: "lightgray",
                                        "& .MuiLinearProgress-bar": {
                                            backgroundColor: "rgba(255, 213, 0, 1)",
                                        }
                                        // borderRadius: 15
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 1,
                                    left: "47%",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontWeight: 700,
                                        paddingTop: 4,
                                        color: "black",
                                    }}
                                >
                                    {activeStep + 1}{" "}
                                    / {steps}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.rightButtonsGridItem}
                    >
                        <StyledNextButton
                            className={classes.nextBackButton}
                            onClick={handleNext}
                            style={{margin: 20}}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default CampaignIntroSlides;
