import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/index";

//Components
import Router from "./Router";

//Styles
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from "@mui/material/styles";


const theme = createTheme({
    typography: {
        fontFamily: ['Helvetica', 'Roboto', 'Arial'].join(','),
    },
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#11cb5f",
        },
        info: {
            main: "#ffffff",
        },
    },
})

function App(props) {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}

export default App;
