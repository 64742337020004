import React from 'react';
// import { Box, } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import EmailTextField from '../components/EmailTextField.component';
import EmailTextField from '../MultiFormSignUp/components/EmailTextField.component';

// Assets
import EarthCharacter from '../../static/images/earth-only.png';

const SignUpData = {
    metaData: {

    },
    coachImage: EarthCharacter,
    dialogTitle: (
        <>
            Sign up to <b>Launch EVs For Earth</b> for Your Audience
        </>
    ),
    intro: (
        <div>
            <Typography variant="h6" align="center">
                <b>Join the Next EVs for Earth Campaign</b>
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
                Want to launch the next campaign to your audience? It's free for you, and free for your audience to participate.
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
                Let’s get started with a few questions so we can understand how to help you launch… It will <b>just take a few minutes.</b>
            </Typography>
        </div>
    ),
    StepData: [
        {
            coachingMessage: (
                <div>
                    <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                        1. About You
                    </Typography>
                    <Box m={1.5} />
                    <Typography variant="h6">
                    Let's start by learning a little about you...
                    </Typography>
                </div>
            ),
            formLink: 'https://surveys.electrifyze.com/mJmNJ1jZmgnZzkEcL1Db/'
        },
        {
            coachingMessage: (
                <div>
                    <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                        2. About Your Organization
                    </Typography>
                    <Box m={1.5} />
                    <Typography variant="h6">
                    Tell us a little about your organization, and the audience you want to reach.
                    </Typography>
                </div>
            ),
            formLink: 'https://surveys.electrifyze.com/gMx4mS00jZmgnZzkEcL1Db/'
        },
        {
            coachingMessage: (
                <div>
                    <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                        3. Preparing for the Next Campaign
                    </Typography>
                    <Box m={1.5} />
                    <Typography variant="h6">
                    Let's understand how we can help you promote EVs For Earth campaigns to your audience!
                    </Typography>
                </div>
            ),
            formLink: 'https://surveys.electrifyze.com/wUcnBTUO1jZmgnZzkEcL1Db/'
        },
        {
            coachingMessage: (
                <div>
                    <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                        4. A Little More About You
                    </Typography>
                    <Box m={1.5} />
                    <Typography variant="h6">
                        Just a few questions so we can get to know you better... 
                    </Typography>
                </div>
            ),
            formLink: 'https://surveys.electrifyze.com/HdDT40jZmgnZzkEcL1Db/'
        },
    ],
    final: (
        <div>
            <Typography variant="h5" align='center'>
                <b>Hooray! You’re signed up!</b>
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
                Our team will reach out to you soon. We'll work with you to promote the EVs for Earth campaign to your audience.
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
            <b>Thank you</b> for working with us to create a Fossil Fuel Free community!
            </Typography>
            <Box m={1.5} />
            
            <Grid container item xs={12} justifyContent="center">
                <Grid item container alignItems='center' style={{paddingRight: 10, width: 125}}>
                    <Typography variant='body2' align='right'>Have questions?</Typography>
                </Grid>
                <EmailTextField />
            </Grid>
            <Box m={0.25} />
        </div>
    ),
    userAlreadyExists: (
        <div>
            <Typography variant="h5" align="center" style={{fontWeight: 600}}>
                You're already signed up!
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
                Looks like you've already signed up for EVs For Earth...
            </Typography>
            <Box m={1.5} />
            <Typography variant="h6">
                Have questions for our team? Get in touch!
            </Typography>
            <Box m={1.5} />
            <Grid container item xs={12} justifyContent="center">
                <Grid item container alignItems='center' style={{paddingRight: 10, width: 125}}>
                    <Typography variant='body2' align='right'>Get in touch</Typography>
                </Grid>
                <EmailTextField />
            </Grid>
            <Box m={0.25} />
        </div>
    )
};

export default SignUpData;