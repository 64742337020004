import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

//Routing
import {
  Route,
  Routes,
  Navigate
} from "react-router-dom";

//Components
import Home from './components/Home/Home';
import ScrollToTop from './components/Routes/ScrollToTop';
import AlertDialog from './components/Styled Components/AlertDialog';
import Main from './components/3CE/Main';

function Router() {
    return (
        <div>
            <ScrollToTop />
            <AlertDialog />

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/3CE" element={<Main />} />

                {/* <Route path="/campaigns/:id" element={<Main />} />
                <Route path="/:campaign/task/:id" element={<Main />} />
                <Route path="/login" element={<Main />} />
                <Route path="/password_reset" element={<Main />} />
                <Route path="/:campaign_key/campaign" element={<Main />} />
                <Route path="/:campaign_key/pre-register" element={<Main />} />
                <Route path="/:campaign_key/campaign-launch" element={<Main />} />
                <Route path="/:campaign_key/campaign-soon" element={<Main />} /> */}

                <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
        </div>          
    );
}

export default Router;
