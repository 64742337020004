import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import EmailForm from './components/EmailForm.component';
import SignUpDialog from './SignUpDialog.component.js';


const SignUpRoot = (props) => {
    const { showEmailForm, openDialogOnStart, autoFocus, inputRef, customFinalCoachMessage } = props;

    const [dialogVisible, setDialogVisible] = useState(openDialogOnStart);
    const [email, setEmail] = useState('');
    const [emailErrorState, setEmailErrorState] = useState(false);

    // Read referral source from URL query params
    let [URLQueryParams] = useSearchParams();
    const referral_source = URLQueryParams.get("referral");
    // Remove a string of "null" being passed into the Coaching Dialog. We'd rather have an empty string.
    let referral_source_cleaned = '';
    if (referral_source) {
        referral_source_cleaned = referral_source;
    }


    const toggleDialogOpen = (action) => {
        if (action === 'open') {
            setDialogVisible(true);
        } else if (action === 'close') {
            setDialogVisible(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailErrorState(false);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate if the user entered a valid email address before letting them proceed.
        if (email.match(/\S+@\S+\.\S+/)) {
            toggleDialogOpen('open');
        } else {
            setEmailErrorState(true);
        }
    } 

    return (
        <div>
            {
                showEmailForm ? 
                    <EmailForm
                        email={email}
                        handleEmailChange={handleEmailChange}
                        handleSubmit={handleSubmit}
                        colorSpecs={{
                            formColor: 'white',
                            buttonColor: 'rgba(255, 213, 0, 1)',
                            buttonHoverColor: 'rgba(214, 179, 0, 1)',
                            buttonTextColor: 'black'
                        }}
                        buttonText='GET STARTED'
                        emailErrorState={emailErrorState}
                        autoFocus={autoFocus}
                        inputRef={inputRef} // Used for allowing autofocus on scroll events
                    />
                :
                    null
            }
            <SignUpDialog
                dialogVisible={dialogVisible}
                toggleDialogOpen={toggleDialogOpen}
                email={email}
                referral_source={referral_source_cleaned}
                handleEmailChange={handleEmailChange}
                customFinalCoachMessage={customFinalCoachMessage}
            />
        </div>
    );
};

export default SignUpRoot;