import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from 'clsx';

// Styles
import { Box, ButtonBase, Typography, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';

//Assets
import ElectrifyzeLogoWhite from "../../static/images/Electrifyze-Logo-white.svg";
// import SignUpDialog from "../3CE/SignUpDialog";
import SignUpRoot from "../MultiFormSignUp/SignUpRoot.component";
import { StyledTaskButton } from "./Buttons";

const useStyles = makeStyles(theme => ({
    headerContainer: {
        backgroundColor: 'black',
        color: 'white',
        width: '100%',
        height: '90px',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            padding: '5px 0px',
        }
    },
    logoContainer: {
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            marginTop: 5,
            marginBottom: 5
            // maxWidth: 200
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        }
    },
    logoText: {
        [theme.breakpoints.down('md')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '24px'
        }
    },
    linksContainer: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
        alignItems: 'center',
        paddingBottom: '5px'
    },
    link: {
        textDecoration: 'none',
        color: 'white',
    },
    linkContainer: {
        padding: '0 20px',
        [theme.breakpoints.only('xs')]: {
            padding: '0 10px',
        },
    },
    borderRight: {
        borderRight: '1px solid white'
    },
    headerLinkText: {
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 16,
        },
    }
}));


const Header = (props) => {
    const classes = useStyles();

    const { handleHomeClick, handleTryClick } = props;
    const [open, toggleDialog] = useState(false);

    const handleClick = () => {
        toggleDialog(!open)
    }

    return (
        <header>
            <Grid container className={classes.headerContainer} >
                <Grid item xs={12} md={5} container className={classes.logoContainer} >
                    <Typography variant='h4' className={classes.logoText}>
                        <b><i>EVs For Earth</i></b>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} container className={classes.linksContainer}>
                    <Grid item className={clsx(classes.linkContainer, classes.borderRight)}>
                        <ButtonBase
                            onClick={handleTryClick}
                            style={{
                                textAlign: "center",
                                textDecoration: "none",
                                color: "white",
                            }}
                        >
                            <Typography variant='h6' className={classes.headerLinkText} >
                                Try it Out
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item className={classes.linkContainer}>
                        <StyledTaskButton onClick={handleClick}>
                            Get Started
                        </StyledTaskButton>
                    </Grid>
                </Grid>
            </Grid>
            {open && (
                // <SignUpDialog open={open} toggleDialog={handleClick} />
                <SignUpRoot openDialogOnStart={open} />
            )}
        </header>
    )

};

export default Header;