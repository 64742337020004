import React, { useEffect, useState } from 'react';

//Styles
// import { makeStyles } from '@material-ui/styles';
// import { 
//     Grid,
//     Button,
//     Typography
// } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles(theme => ({
    parentContainer: {
        backgroundColor: 'white',
        padding: 10,
        border: '1px solid black',
        borderRadius: 10,
        // width: '100%'
    },
    emailContainer: {
        marginRight: 10
    },
    copyButton: {
        backgroundColor: 'rgba(255, 213, 0, 1)',
        color: 'black',
        border: '1px solid black',
        '&:hover': {
            backgroundColor: 'rgba(214, 179, 0, 1)'
        },

    }
}));

function EmailTextField(props) {
    const classes = useStyles()

    const [copied, setCopied] = useState(false)
    const emailToCopy = 'mail@electrifyze.com';

    const handleCopyClick = () => {
        if (/*@cc_on!@*/false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData('Text', emailToCopy);
        } else {
            navigator.clipboard.writeText(emailToCopy);
        }

        setCopied(true)
    };


    return (
        <div className={classes.parentContainer}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item className={classes.emailContainer}>
                    <a
                        href={`mailto:${emailToCopy}?subject=EVs for Earth Questions`}
                        id='mail-share-link'
                    >
                        <Typography variant='body1'>
                            {emailToCopy}
                        </Typography>
                    </a>
                </Grid>
                
                <Grid item>
                    <Button variant="outlined" onClick={handleCopyClick} className={classes.copyButton}>
                        {copied ? "Copied!" : "Copy"}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default EmailTextField;