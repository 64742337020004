import React from "react";
import NativeForms from "native-forms-react";
import { useLocation } from "react-router-dom";

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";

//Components
import { CustomDialogTitle } from "../Styled Components/CustomDialogTitle";
import CoachMessage from "../Styled Components/CoachMessage";

//Assets
import Earth from "../../static/images/earth-only.png";

//Helpers
import { paramsToObject } from "../../helpers/functions";


// const useStyles = makeStyles((theme) => ({
//     dialogBox: {
//         marginTop: -20,
//         marginLeft: 20,
//         marginRight: 20,
//         marginBottom: 20,
//         [theme.breakpoints.down('lg')]: {
//             margin: 0,
//         },
//     },
//     dialogPaper: {
//         [theme.breakpoints.down('lg')]: {
//             margin: 12,
//         },
//     },
//     dialogContent: {
//         paddingBottom: 10,
//         msOverflowStyle: "scrollbar",
//         [theme.breakpoints.down('xl')]: {
//             padding: 10,
//         },
//     },
//     pulse: {
//         marginBottom: 15,
//         animation: `$pulse 1.5s ${theme.transitions.easing.easeInOut} infinite`,
//     },
//     "@keyframes pulse": {
//         "25%": {
//             transform: "scale(0.9)",
//         },
//         "75%": {
//             transform: "scale(1.1)",
//         },
//     },
//     giftCardImage: {
//         maxHeight: 120,
//         maxWidth: "100%",
//     },
//     bounce: {
//         marginBottom: 15,
//         animation: `$bounce 3s ${theme.transitions.easing.easeInOut} infinite`,
//     },
//     "@keyframes bounce": {
//         "0%, 33%, 7%, 17%, 27%": {
//             webkitTransform: "translateY(0)",
//             msTransform: "translateY(0)",
//             transform: "translateY(0)",
//         },
//         "13%": {
//             webkitTransform: "translateY(-30px)",
//             msTransform: "translateY(-30px)",
//             transform: "translateY(-30px)",
//         },
//         "20%": {
//             webkitTransform: "translateY(-15px)",
//             msTransform: "translateY(-15px)",
//             transform: "translateY(-15px)",
//         },
//     },
//     messageText: {
//         [theme.breakpoints.down('lg')]: {
//             fontSize: "1rem",
//         },
//     },
// }));

function CampaignIntroDialog(props) {
    //Pass the color from the parent component to the styles
    const classes = {} // useStyles();
    const { open, toggleDialog, handleBeginCampaignClick } = props;
    
    
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

    let referralEmail = params.referral;
    
    const formId = "4RTYW1jZmgnZzkEcL1Db";
    const formURL = `https://surveys.electrifyze.com/${formId}/?Referral=${referralEmail || ''}`

    return (
        <Dialog
            open={open}
            maxWidth="md"
            onBackdropClick={toggleDialog}
            classes={{ paper: classes.dialogPaper }}
        >
            <CustomDialogTitle onClose={toggleDialog}>
                Try it out… Get a sneak preview of <b>EVs For Earth</b>
            </CustomDialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.dialogBox}>
                    <CoachMessage
                        coachImage={Earth}
                        message={
                            <div>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    className={classes.messageText}
                                >
                                    <b>
                                        Want to Preview an <br/> EVs For Earth Campaign? 
                                    </b>
                                </Typography>
                                <br />
                                <Typography
                                    variant="h5"
                                    className={classes.messageText}
                                >
                                    Fill out the form below to get started...
                                </Typography>
                                <Typography
                                    variant="caption"
                                    className={classes.messageText}
                                >
                                    We work hard to protect your privacy, and we don't believe in spamming people.
                                </Typography>
                            </div>
                        }
                    />

                    <div
                        style={{
                            display: "flex",
                            marginTop: 10,
                            border: "1px solid black",
                        }}
                    >
                        <NativeForms
                            form={formURL}
                            onBeforeSend={handleBeginCampaignClick}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CampaignIntroDialog;
