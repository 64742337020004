import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Actions
import { closeAlert } from "../../actions/alert_actions";

//Styles
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


function AlertDialog(props) {
    /* const { alert } = props;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (alert.data !== null) setVisible(true);
        else setVisible(false);
    }, [alert]);

    if (alert.data !== null)
        return (
            <Dialog open={visible} fullWidth={true} maxWidth="xs">
                <DialogContent sx={{
                    padding: 20,
                    paddingBottom: 0,
                }}>
                    <Typography variant="h6">{alert.data.text}</Typography>
                </DialogContent>
                <DialogActions sx={{
                    padding: 20
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.closeAlert}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        );
    else  */return null;
}

export default AlertDialog;
