import React from 'react';

// import { StepConnector, makeStyles } from '@material-ui/core';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    stepper: {
        paddingTop: 0,
        paddingBottom: 5,
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            paddingBottom: 5,
            paddingLeft: 0,
            paddingRight: 0,
        },
    }
}));

const ProgressIndicator = (props) => {
    const {currentStep, numberOfSteps} = props;
    const classes = useStyles();

    return (
        <Stepper
            activeStep={currentStep} 
            //alternativeLabel={width < 600 ? false : true} 
            connector={<StepConnector />}
            className={classes.stepper}
            //orientation={width < 600 ? "vertical" : "horizontal"}
        >

            {Array(numberOfSteps).fill('').map((step) => (
                <Step key={step}>
                    <StepLabel />
                </Step>
            ))}
        </Stepper>
    );
};

export default ProgressIndicator;